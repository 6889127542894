module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Kinderwelt","name":"Kinderwelt","description":"Entdecke mit Gian und Giachen die Kinderwelt von Graubünden","icon":"static/summer/appicon-512x512.png","start_url":"/","display":"fullscreen","orientation":"any","theme_color":"#ffffff","background_color":"#ffffff","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8a9a9e386b2d8727f4bb2788bc13aad2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
