/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const Sentry = require('@sentry/browser');
const { initTracking } = require('./src/vendor/tracking');
require('./src/index.scss');

exports.onClientEntry = () => {
  const sentryDSN = process.env.GATSBY_SENTRY_DSN;
  const sentryEnv = process.env.GATSBY_SENTRY_ENV;
  if (sentryDSN && sentryEnv) {
    Sentry.init({ dsn: sentryDSN, environment: sentryEnv, release: process.env.GATSBY_SENTRY_RELEASE });
  }
  initTracking();
};
