/* eslint-disable */
export const initTracking = () => {
  if (process.env.GATSBY_FUSEDECK_ENABLED) {
    (function(f, u, s, e_, d, e, c, k, n_) {
      try {
        var cos = document.cookie.split(';');
        for (var i = 0; i < cos.length; i++) {
          var co = cos[i].trim().split('=');
          if (co[0] == 'fdTrackingType' + e) {
            c = co[1];
          }
          if (co[0] == 'fdEnvId') {
            k = co[1];
          }
        }
        f[d] = f[d] || [];
        window.addEventListener('load', function() {
          fdWinLoaded = !0;
        });
        var p = u.getElementsByTagName(s)[0];
        var sc = u.createElement(s);
        sc.async = true;
        sc.src =
          e_ +
          '?p=' +
          e +
          '&l=' +
          (d != null ? d : '') +
          (c != null ? '&c=' + c : '') +
          (k != null ? '&e=' + k : '') +
          '&h=' +
          encodeURIComponent(location.href) +
          (n_ != null ? '&a=' + encodeURIComponent(n_) : '');
        p.parentNode.insertBefore(sc, p);
      } catch (e) {}
    })(window, document, 'script', 'https://fuse.graubuenden.ch/d/tm.js', null, 'YMY2EarKEZ', null, null, null);
  }
};
